import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';

const About: React.FC<PageProps> = () => (
   <Layout pageTitle="Insightful Analytics">
      <section className="feature-hero">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-10 col-md-5 col-lg-4 margin-top-l">
                  <h1>Insightful Analytics</h1>
                  <p className="subtitle">Our real-time analytics give you a clear picture into your students' progress.</p>
                  <p className="description">Lingco lets you know exactly where your students are in their journey so that
                  you can tailor your instruction as needed. With Lingco, there are fewer surprises on test day.</p>
               </div>
               <div className="col-xs-12 col-sm-10 col-md-5 col-lg-5 col-lg-offset-1">
                  <div className="feature-img wide"><img src="/img/feature-screen-analytics.png"
                     alt="feature-screen-analytics" /></div>
               </div>
            </div>
         </div>
      </section>
      <section className="platform-features">
         <div className="container-fluid">
            <div className="row center-xs middle-xs">
               <div className="col-xs-12 col-sm-6 text-center margin-bottom-l">
                  <h3>Content Recommendations</h3>
                  <p className="text-larger">Since Lingco is always learning alongside your students, it can provide
                  recommendations on suitable content for your class. This includes videos, music, activities, and
                        texts based on the vocabulary and grammar skills your students know and are working on.</p>
               </div>
               <div className="col-xs-12 col-sm-10">
                  <div className="feature-img full-width"><img src="/img/feature-crop-recommendation.svg"
                     alt="feature-crop-recommendation" /></div>
               </div>
            </div>
         </div>
      </section>
      <section className="feature-column">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-10">
                  <div className="row center-xs">
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/book-text-view.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Smart Placement Assessments</h4>
                              <p>Reliably and efficiently place students based on recommendations from our
                              adaptive placement tests that align directly with a program's curriculum and
                                        provide instant results without the need for a proctor or grader.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/business-graph-pie-2.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Uncover Trends</h4>
                              <p>Using per-question and per-rubric statistics on assignments, instructors can
                              visualize student performance. Patterns, common errors, and misconceptions can
                                        be identified and fixed.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/organization-graph.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Track Growth</h4>
                              <p>With pre and post course proficiency assessments, instructors can use Lingco to
                              analyze and measure individual, group, and program growth and make informed
                                        decisions on curriculum and training.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="section-divider"></div>
      </section>
      <section className="feature-more">
         <div className="container-fluid">
            <div className="row center-xs text-center margin-bottom-xl">
               <div className="col-xs-12 col-sm-10">
                  <h5 className="grey">Want to see more features? Check these out</h5>
               </div>
            </div>
            <div className="row center-xs">
               <div className="col-xs-12 col-md-5 margin-bottom-m-md"><Link to="/platform/personalized-learning">
                  <div className="card">
                     <div className="row middle-xs">
                        <div className="col-xs-12 col-md-6 margin-top-l">
                           <h4>Personalized Learning</h4>
                           <p>Every student is unique. It’s time that we take a personal approach.</p>
                        </div>
                        <div className="feature-more-img section"><img src="/img/feature-more-section.svg"
                           alt="feature-more-section" /></div>
                     </div>
                  </div>
               </Link></div>
               <div className="col-xs-12 col-md-5"><Link to="/platform/course-management">
                  <div className="card">
                     <div className="row middle-xs">
                        <div className="col-xs-12 col-md-6 margin-top-l">
                           <h4>Course Management</h4>
                           <p>Lingco has all of the tools you need to successfully run a language course.</p>
                        </div>
                        <div className="feature-more-img management"><img src="/img/feature-more-management.svg"
                           alt="feature-more-management" /></div>
                     </div>
                  </div>
               </Link></div>
            </div>
         </div>
      </section>
   </Layout>
);

export default About;
